export const mockSearchService = {
  id: "123e4567-e89b-12d3-a456-426614174000",
  name: "test search service",
  description: "description of test search service",
  endpoint: "test endpoint",
  searchNeuralHybridSearchEnabled: true,
  searchLexicalWeight: 0.5,
  searchSemanticWeight: 0.5,
  searchSpellCorrectionsEnabled: true,
  boostWithSignalsEnabled: true,
  boostSignalsWeight: 10,
  boostFieldsEnabled: true,
  boostFields: {
    id: 5,
    name: 5,
    title: 5,
    product: 5,
    sku: 5
  },
  boostFreshnessEnabled: true,
  boostFreshnessWeight: 5,
  sortFields: [
    {
      field: "Relevance",
      order: "Ascending"
    },
    {
      field: "Date",
      order: "Descending"
    }
  ],
  creationDate: "2025-01-14T10:00:00Z"
}

export const mockSiteEnvironments = [
  {
    id: "123e4567-e89b-12d3-a456-426614174000",
    name: "Dev",
    integratedSystemId: "123e4567-e89b-12d3-a456-426614174000",
    fusionAppId: "123e4567-e89b-12d3-a456-426614174000",
    creationDate: "2025-01-14T10:00:00Z"
  },
  {
    id: "123e4567-e89b-12d3-a456-426614174000",
    name: "Prod",
    integratedSystemId: "123e4567-e89b-12d3-a456-426614174000",
    fusionAppId: "123e4567-e89b-12d3-a456-426614174000",
    creationDate: "2025-01-14T10:05:00Z"
  }
]

export const mockCustomerSites = [
  {
    id: "123e4567-e89b-12d3-a456-426614174000",
    name: "Site 1",
    creationDate: "2025-01-14T10:00:00Z"
  },
  {
    id: "123e4567-e89b-12d3-a456-426614174000",
    name: "Site 2",
    creationDate: "2025-01-14T10:00:00Z"
  }
]

export const mockTypeaheadService = {
  id: "123e4567-e89b-12d3-a456-426614174000",
  endpoint: "test endpoint url",
  productAttributeBrandEnabled: true,
  productAttributeCategoryEnabled: true,
  creationDate: "2025-01-14T10:00:00Z"
}