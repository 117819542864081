import { mockCustomerSites, mockSearchService, mockSiteEnvironments, mockTypeaheadService } from '../../components/lw-sites/mock-data';
import { CCApiService } from './api-service';
import { UIConfig, CustomerSite } from './interfaces';

export class SitesService {
  static ApiService: CCApiService;
  static sitesAPI: string;
  static apiBasePath: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.sitesAPI = `${this.apiBasePath}/customers/${customerId}/sites`;
  }

  static async getCustomerSites({ mock }): Promise<CustomerSite[]> {
    if (mock) {
      return Promise.resolve(mockCustomerSites)
    }
    return this.ApiService.performFetch(this.sitesAPI).then(sites => {
      return sites;
    });
  }

  static async getSiteEnvironments({ siteId, mock }): Promise<any> {
    if (mock) {
      return Promise.resolve(mockSiteEnvironments);
    }
    return this.ApiService.performFetch(`${this.sitesAPI}/${siteId}/environments`).then(sites => {
      return sites;
    });
  }

  static async getSearchService({ siteId, environmentId, mock }): Promise<any> {
    if (mock) {
      return Promise.resolve(mockSearchService)
    }
    return this.ApiService.performFetch(`${this.sitesAPI}/${siteId}/environments/${environmentId}/search-service`).then(sites => {
      return sites;
    });
  };

  static async getTypeaheadService({ siteId, environmentId, mock }): Promise<any> {
    if (mock) {
      return Promise.resolve(mockTypeaheadService)
    }
    return this.ApiService.performFetch(`${this.sitesAPI}/${siteId}/environments/${environmentId}/typeahead-service`).then(sites => {
      return sites;
    });
  }
}
